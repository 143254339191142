<template>
  <v-dialog
    v-model="isOpen"
    width="28rem"
    overlay-color="black"
    overlay-opacity="0.8"
    @click:outside="$emit('update:is-open', false)"
  >
    <v-card
      class="py-5"
      color="#222222"
    >
      <v-icon
        class="absolute-close"
        @click="$emit('update:is-open', false)"
      >
        {{ icons.mdiClose }}
      </v-icon>

      <v-card-text class="pt-4 pb-0">
        <v-card-title class="justify-center">
          {{ t('tooltip.share') }}
        </v-card-title>
      </v-card-text>

      <v-card-text>
        <v-row
          justify="space-between"
          align="center"
          class="px-0 py-1 my-0 mx-3"
        >
          <v-col class="d-flex justify-center align-center pa-2">
            <s-email
              :share-options="computedEmail"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                :width="iconSize"
                :height="iconSize"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
                fill="#ffffff"
              >
                <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
              </svg>
            </s-email>
          </v-col>

          <v-col
            v-if="computedIsMobile"
            class="d-flex justify-center align-center pa-2"
          >
            <s-sms
              :window-features="windowFeatures"
              :share-options="computedSms"
              :use-native-behavior="useNativeBehavior"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                :width="iconSize"
                :height="iconSize"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
                fill="#ffffff"
              >
                <path d="M12 1c-6.338 0-12 4.226-12 10.007 0 2.05.739 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 9.236 2.247 15.968-3.405 15.968-9.457 0-5.812-5.701-10.007-12-10.007zm-4.704 12.474c-.416.33-.996.495-1.74.495-.637 0-1.201-.123-1.69-.367l.274-1.083c.494.249.993.375 1.501.375.293 0 .521-.056.686-.167.315-.214.334-.646.023-.892-.149-.117-.404-.236-.769-.357-1.097-.366-1.645-.937-1.645-1.716 0-.503.202-.917.604-1.243.404-.325.943-.488 1.614-.488.586 0 1.096.099 1.535.298l-.299 1.049c-.401-.187-.82-.28-1.254-.28-.267 0-.476.052-.627.153-.299.204-.293.57-.035.787.126.107.428.246.91.416.532.188.92.42 1.167.698.246.277.369.621.369 1.034 0 .528-.208.958-.624 1.288zm6.638.401c-.091-2.281-.136-3.829-.136-4.642h-.026c-.138.611-.605 2.128-1.399 4.547h-1.031c-.476-1.669-1.178-4.547-1.178-4.547h-.018l-.204 4.642h-1.229l.366-5.75h1.792l1.014 3.967 1.228-3.967h1.811l.309 5.75h-1.299zm5.565-.377c-.419.334-1.011.502-1.76.502-.606 0-1.179-.107-1.727-.381l.29-1.147c.48.246.973.39 1.522.39.286 0 .51-.054.667-.162.302-.204.313-.61.022-.841-.145-.114-.4-.231-.757-.353-1.107-.367-1.67-.954-1.67-1.745 0-.51.208-.938.618-1.267.407-.328.956-.494 1.631-.494.587 0 1.109.101 1.55.3l.023.012-.316 1.111c-.404-.19-.81-.295-1.274-.295-.258 0-.463.05-.609.148-.281.192-.275.533-.032.738.123.103.425.242.899.41.536.188.932.426 1.181.706.25.283.378.636.378 1.055-.001.537-.215.979-.636 1.313z" />
              </svg>
            </s-sms>
          </v-col>

          <v-col class="d-flex justify-center align-center pa-2">
            <s-whats-app
              :window-features="windowFeatures"
              :share-options="computedWs"
              :use-native-behavior="useNativeBehavior"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                :width="iconSize"
                :height="iconSize"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
                fill="#25d366"
              >
                <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
              </svg>
            </s-whats-app>
          </v-col>

          <v-col class="d-flex justify-center align-center pa-2">
            <s-twitter
              :window-features="windowFeatures"
              :share-options="computedTw"
              :use-native-behavior="useNativeBehavior"
            >
              <svg
                :width="iconSize"
                :height="iconSize"
                xmlns="http://www.w3.org/2000/svg"
                xml:space="preserve"
                xmlns:serif="http://www.serif.com/"
                style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"
                aria-hidden="true"
                focusable="false"
                fill="#ffffff"
                viewBox="0 0 512 512"
              >
                <path
                  d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                />
              </svg>
            </s-twitter>
          </v-col>

          <v-col class="d-flex justify-center align-center pa-2">
            <s-telegram
              :window-features="windowFeatures"
              :share-options="computedTl"
              :use-native-behavior="useNativeBehavior"
            >
              <svg
                :width="iconSize"
                :height="iconSize"
                xmlns="http://www.w3.org/2000/svg"
                xml:space="preserve"
                xmlns:serif="http://www.serif.com/"
                style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"
                aria-hidden="true"
                focusable="false"
                fill="#0088cc"
                viewBox="0 0 24 24"
              >
                <path
                  id="telegram-1"
                  d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z"
                />
              </svg>
            </s-telegram>
          </v-col>
        </v-row>

        <v-row class="my-2 mx-3 px-0 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center flex-column"
          >
            <v-text-field
              id="refCopy"
              ref="refVCopy"
              v-model="link"
              :label="t('fields.link')"
              style="width: 100%;"
              dense
              outlined
              readonly
              hide-details
            >
              <template v-slot:append>
                <v-fade-transition leave-absolute>
                  <v-icon
                    size="25"
                    :color="!isCopied ? 'white' : 'success'"
                    @click="copyAction"
                  >
                    {{ !isCopied ? icons.mdiContentCopy : icons.mdiCheck }}
                  </v-icon>
                </v-fade-transition>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable vuejs-accessibility/alt-text */
import {
  ref, computed, getCurrentInstance,
} from '@vue/composition-api'
import { mdiClose, mdiContentCopy, mdiCheck } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'
import { success, error } from '@core/utils/toasted'
import { useClipboard } from '@vueuse/core'
import { copyText } from 'vue3-clipboard'
import { isMobile } from '@core/utils'
import {
  SEmail, SSms, SWhatsApp, STwitter, STelegram,
} from 'vue-socials'

export default {
  name: 'Share',
  components: {
    SEmail,
    SSms,
    SWhatsApp,
    STwitter,
    STelegram,
  },
  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    link: {
      type: String,
      require: true,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    date: {
      type: String,
      require: true,
      default: '',
    },
    startTime: {
      type: String,
      require: true,
      default: '',
    },
    location: {
      type: String,
      require: true,
      default: '',
    },
    sport: {
      type: String,
      require: true,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { userData } = useCryptoJs()
    const { copy, isSupported } = useClipboard()
    const vm = getCurrentInstance().proxy

    const refVCopy = ref(null)
    const refCopy = ref(null)
    const isCopied = ref(false)

    const computedIsMobile = computed(() => isMobile())

    const computedDate = computed(() => props.date)
    const computedStartTime = computed(() => props.startTime)
    const computedLocation = computed(() => props.location)
    const computedSport = computed(() => props.sport)
    const computedLink = computed(() => props.link)

    const windowFeatures = ref({})
    const useNativeBehavior = ref(false)

    const computedText = computed(() => {
      const text = `Date: ${computedDate.value}\nTime: ${computedStartTime.value}\nLocation: ${computedLocation.value}\n\nManage booking:\n${computedLink.value}\n\nVideo recording available via the link.`

      return text
    })

    const computedTextWs = computed(() => {
      const text = `Date: ${computedDate.value}\nTime: ${computedStartTime.value}\nLocation: ${computedLocation.value}\n\nVideo recording available via the link.\n\nManage booking:\n${computedLink.value}`

      return text
    })

    const computedEmail = computed(() => {
      const body = {
        mail: '',
        cc: [],
        bcc: [],
        subject: 'Fanaty',
        body: computedText.value,
      }

      return body
    })

    const computedSms = computed(() => {
      const body = {
        number: '',
        body: computedText.value,
      }

      return body
    })

    const computedWs = computed(() => {
      const body = {
        number: '',
        text: computedTextWs.value,
      }

      return body
    })

    const computedFb = computed(() => {
      const body = {
        url: `${computedLink.value}`,
        quote: computedText.value,
        hashtag: '#Fanaty',
      }

      return body
    })

    const computedTw = computed(() => {
      const body = {
        url: `${computedLink.value}`,
        text: computedText.value,
        hashtags: [],
        via: '',
      }

      return body
    })

    const computedTl = computed(() => {
      const body = {
        url: `${computedLink.value}`,
        text: computedText.value,
      }

      return body
    })

    const computedIsOpen = computed({
      set: val => emit('update:is-open', val),
      get: () => props.isOpen,
    })

    const onSave = () => {
      emit('onSave')
    }

    const onClose = () => {
      console.log('onClose')
    }

    const onOpen = () => {
      console.log('onOpen')
    }

    const onBlock = () => {
      console.log('onBlock')
    }

    const onFocus = () => {
      console.log('onFocus')
    }

    const successCopy = () => {
      setTimeout(() => {
        success(vm.$t('tooltip.copied'))
        isCopied.value = true

        setTimeout(() => {
          isCopied.value = false
          refVCopy.value.onBlur()
        }, 2000)
      }, 500)
    }

    const copyToClipboard = () => {
      const el = document.querySelector('#refCopy')

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        const editable = el.contentEditable
        const { readOnly } = el

        el.contentEditable = true
        el.readOnly = true

        const range = document.createRange()
        range.selectNodeContents(el)

        const selection = window.getSelection()
        selection.removeAllRanges()
        selection.addRange(range)
        el.setSelectionRange(0, 999999)

        el.contentEditable = editable
        el.readOnly = readOnly
      } else {
        el.select()
      }

      document.execCommand('copy')
      successCopy()
    }

    const copyTextAction = () => {
      copyText(refCopy.value.value, undefined, e => {
        if (e) {
          error(vm.$t('tooltip.not_copy'))
        } else {
          success(vm.$t('tooltip.copied'))
        }
      })
    }

    const copyAction = () => {
      if (!isSupported) {
        copyToClipboard()
      } else if (!isCopied.value) {
        copy(props.link).then(() => {
          successCopy()
        })
      }
    }

    return {
      // data
      userData,
      refCopy,
      refVCopy,
      isCopied,
      computedIsMobile,

      windowFeatures,
      useNativeBehavior,

      computedDate,
      computedStartTime,
      computedLocation,
      computedSport,

      computedEmail,
      computedSms,
      computedWs,
      computedFb,
      computedTw,
      computedTl,

      // computed
      computedIsOpen,

      // methods
      onSave,
      copyAction,
      copyTextAction,
      copyToClipboard,

      onClose,
      onOpen,
      onBlock,
      onFocus,

      // i18n
      t,

      // icons
      icons: {
        mdiClose,
        mdiContentCopy,
        mdiCheck,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
  .absolute-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #222222;
    color: #ffffff6b;
    border-radius: 50px !important;
    padding: 2px;
  }

  .phone-item {
    min-height: 25px;
    height: 25px;
  }

  .btn-not-hov {
    color: #ff3232 !important;
    caret-color: #ff3232 !important;
    cursor: pointer;
  }

  .no-bg-hover::before {
    background-color: transparent !important;
  }
</style>
