import { ref } from '@vue/composition-api'
import { getUserPaymentMethods } from '@api'
import useCryptoJs from '@core/utils/useCryptoJs'

export default function useReservation() {
  const { decryptData } = useCryptoJs()

  const paymentMethods = ref([])

  const fetchPaymentMethods = async () => {
    try {
      const response = await getUserPaymentMethods()
      paymentMethods.value = decryptData(response.data)
      paymentMethods.value.unshift({
        id: 0,
        last_4: null,
        exp_month: null,
        exp_year: null,
        brand: 'Add new card',
        exp_date_str: null,
      })
    } catch (e) {
      console.error(e)
      paymentMethods.value = []
    }
  }

  return {
    // data
    paymentMethods,

    // mEthods
    fetchPaymentMethods,
  }
}
