<template>
  <div>
    <span
      v-if="showIcon"
      name="video-icon"
      style="
        position: absolute;
        top: 35%;
        right: 50%;
        transform: translate(50%, 50%);
        background-color: #00000099;
        border-radius: 100px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <v-icon
        :color="iconColor"
        size="18"
      >
        {{ icon ? icon : icons.mdiPlay }}
      </v-icon>
    </span>

    <v-chip
      class="absolute-chip text-xs"
      :color="timeColor"
      small
    >
      {{ time ? time : '00:00' }}
    </v-chip>
  </div>
</template>

<script>
import { mdiPlay } from '@mdi/js'

export default {
  name: 'VideoIcon',
  props: {
    time: {
      type: String,
      default: '',
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'secondary',
    },
    timeColor: {
      type: String,
      default: '#1d1d1dd4',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      icons: {
        mdiPlay,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/preset/preset/pages/landing.scss';
</style>
