<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-bottom-transition"
    class="pay-dialog"
    content-class="height-mid"
    overlay-color="black"
    overlay-opacity="0.8"
    @click:outside="$emit('update:is-open', false)"
  >
    <v-card
      class="pa-3"
      rounded
      style="width: 100%; border-radius: 10px 10px 0 0 !important;"
      color="#353535"
    >
      <v-form ref="form">
        <v-app-bar
          flat
          color="rgba(0, 0, 0, 0)"
          dense
          class="px-4"
        >
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-body-1 white--text pl-0">
            {{ t('pay_dialog.add_payment_method') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            class="close-btn"
            icon
            small
            style="background-color: #3f3e3e"
            @click="$emit('update:is-open', false)"
          >
            <v-icon>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text class="mt-5 pb-3 px-2 justify-center d-flex flex-column">
          <div v-if="userData">
            <v-select
              v-model="selectedCard"
              :placeholder="t('payment_methods.card_number')"
              :items="paymentMethods"
              item-text="last_4"
              item-value="id"
              class="mb-2"
              outlined
              rounded
              hide-details
              :rules="[validators.required]"
            >
              <template
                slot="selection"
                slot-scope="data"
              >
                <span class="text-capitalize mr-1">{{ data.item.brand }}</span>
                <span v-if="data.item.last_4">ending {{ data.item.last_4 }}</span>
              </template>
              <template
                slot="item"
                slot-scope="data"
              >
                <span class="text-capitalize mr-1">{{ data.item.brand }}</span>
                <span v-if="data.item.last_4">ending {{ data.item.last_4 }}</span>
              </template>
            </v-select>
          </div>

          <div v-if="!userData || ( userData && selectedCard === 0)">
            <v-text-field
              v-model="formData.card_number"
              v-mask="'#### #### #### ####'"
              :placeholder="t('payment_methods.card_number')"
              class="mb-2"
              outlined
              rounded
              :rules="[validators.cardNumberRule]"
              hide-details
              @keyup="identifyCard"
            >
              <template v-slot:append>
                <div style="width: 100%">
                  <v-icon
                    v-if="cardFilter === '*'"
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiCreditCardOutline }}
                  </v-icon>

                  <v-img
                    v-else
                    height="30"
                    width="30"
                    :src="require(`@/assets/images/cards/${cardFilter}.png`)"
                  />
                </div>
              </template>
            </v-text-field>

            <div class="d-flex justify-space-between">
              <v-text-field
                v-model="formData.card_date"
                v-mask="'##/##'"
                :label="t('payment_methods.expiration_date')"
                :placeholder="t('payment_methods.card_date')"
                class="mb-2 pr-2"
                :rules="[validators.required, validators.isValidDateRule]"
                outlined
                rounded
                hide-details
                :prepend-inner-icon="icons.mdiCalendar"
              ></v-text-field>

              <v-text-field
                v-model="formData.cvv"
                v-mask="'####'"
                :placeholder="t('payment_methods.cvv')"
                class="mb-2 pl-2"
                outlined
                rounded
                :rules="[validators.required, validators.integerValidator, validators.lengthBetweenValidator(formData.cvv, 3, 4) ]"
                hide-details
              />
            </div>

            <v-text-field
              v-if="!userData"
              v-model="formData.name"
              :placeholder="t('users.name')"
              class="mb-2"
              outlined
              rounded
              :rules="[validators.required]"
              hide-details
            />

            <v-text-field
              v-if="!userData"
              v-model="formData.email"
              :placeholder="t('users.email')"
              class="mb-2"
              outlined
              rounded
              :rules="[validators.required, validators.emailValidator]"
              hide-details
            />
          </div>
        </v-card-text>

        <v-card-text class="mt-5 px-2 justify-center d-flex flex-column">
          <div class="d-flex justify-space-between mb-2">
            <span>Subtotal</span>
            <span class="white--text">{{ formatCurrency(subtotal) }}</span>
          </div>
          <div class="d-flex justify-space-between mb-2">
            <span>Service fee and taxes</span>
            <span class="white--text">{{ formatCurrency(taxes) }}</span>
          </div>
          <div
            v-if="canCancel"
            class="d-flex justify-space-between mb-2"
          >
            <span>Free cancellation before</span>
            <span class="white--text">{{ cancelDate }}</span>
          </div>
        </v-card-text>

        <v-card-actions class="d-flex flex-column justify-center pt-0 pb-3 px-2">
          <v-btn
            class="text-body-1 text-capitalize rounded-lg"
            color="secondary"
            block
            dark
            @click="onSave"
          >
            {{ `${t('pay_video.pay')} ${formatCurrency(total)}` }}
          </v-btn>

          <span class="text-center text-xs mt-2">
            {{ t('pay_dialog.by_pressing_pay') }} <a
              href="https://www.fanaty.com/terms.html"
              target="_blank"
              referrerpolicy="no-referrer"
              class="secondary--text terms-and-policies"
            >{{ t('pay_dialog.terms_and_conditions') }}</a> {{ t('pay_dialog.and') }} <a
              href="https://www.fanaty.com/terms.html"
              target="_blank"
              referrerpolicy="no-referrer"
              class="secondary--text terms-and-policies"
            >{{ t('pay_dialog.privacy_policy') }}</a>.</span>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  ref, computed, watch, onBeforeMount,
} from '@vue/composition-api'
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiClose,
  mdiMenuDown,
  mdiCreditCardOutline,
} from '@mdi/js'
import {
  required,
  minLengthValidator,
  integerValidator,
  emailValidator,
  lengthBetweenValidator,
} from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { formatCurrency } from '@core/utils'
import useCryptoJs from '@core/utils/useCryptoJs'
import CardValidator from 'card-validator'

import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'

export default {
  name: 'Pay',
  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    taxes: {
      type: Number,
      required: true,
      default: 0,
    },
    subtotal: {
      type: Number,
      required: true,
      default: 0,
    },
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    cancelDate: {
      type: String,
      required: true,
      default: '',
    },
    canCancel: {
      type: Boolean,
      required: true,
      default: false,
    },
    paymentMethods: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { userData, encrypt } = useCryptoJs()

    const form = ref(null)
    const formData = ref({
      card_number: null,
      card_date: null,
      exp_month: null,
      exp_year: null,
      cvv: null,
      name: null,
      email: null,
    })
    const cardFilter = ref('*')
    const selectedCard = ref(null)
    const result = computed(() => CardValidator.number(formData.value.card_number))
    const iso = ref(null)
    const mountedIso = ref(false)
    const menu = ref(false)
    const modal = ref(false)
    const date = ref(
      new Date().toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )

    const isValidDateRule = value => {
      if (value === null || value === undefined || value === '') {
        return true
      }

      const regex = /^((0[1-9])|(1[0-2]))\/(\d{2})$/
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear() - 2000
      const currentMonth = currentDate.getMonth() + 1

      const dateParts = value.split('/')
      const expirationYear = parseInt(dateParts[1], 10)
      const expirationMonth = parseInt(dateParts[0], 10)

      if (!regex.test(value)) {
        return false
      }

      if (expirationYear < currentYear) {
        return false
      }

      if (expirationYear === currentYear && expirationMonth < currentMonth) {
        return false
      }

      return true
    }

    const computedIsOpen = computed(() => props.isOpen)
    const computedPaymentMethods = computed(() => props.paymentMethods)

    const cardNumberRule = value => {
      if (!value) return 'Required'
      if (!result.value.isValid) return 'Invalid Card Number'

      return true
    }

    const identifyCard = () => {
      const niceType = result.value?.card?.niceType
      let filter = '*'
      let filterCard = '*'
      if (niceType) {
        const type = niceType.toLowerCase().replace(/\s/g, '')
        filter = `#${type}`
        filterCard = `${type}`
      }

      cardFilter.value = filterCard
      if (iso.value) iso.value.arrange({ filter })
    }

    const onSave = () => {
      if (form.value.validate()) {
        let encryptPay = null
        if (!selectedCard.value) {
          const [momth, year] = formData.value.card_date.split('/')
          const bodyInfo = {
            card_number: formData.value.card_number,
            exp_month: momth,
            exp_year: year,
            cvv: formData.value.cvv,
            name: formData.value.name,
            email: formData.value.email,
          }
          encryptPay = encrypt(bodyInfo)
        }
        const body = {
          payment_method_info: encryptPay,
          payment_method_id: selectedCard.value ? selectedCard.value : null,
          user_id: userData.value ? userData.value.user_id : null,
        }
        emit('save', body)
      }
    }

    watch([computedIsOpen], () => {
      if (!computedIsOpen.value) {
        formData.value = {
          card_number: null,
          card_date: null,
          exp_month: null,
          exp_year: null,
          cvv: null,
          name: null,
          email: null,
        }
        form.value.resetValidation()
      } else if (props.paymentMethods.length === 2) {
        selectedCard.value = computedPaymentMethods.value[1].id
      }
    })

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    return {
      // data
      userData,
      form,
      formData,
      cardFilter,
      cardNumberRule,
      selectedCard,
      mountedIso,
      menu,
      modal,
      date,

      // computed
      computedIsOpen,

      // methods
      onSave,
      formatCurrency,
      identifyCard,

      // validations
      required,

      // i18n
      t,

      // validators
      validators: {
        required,
        minLengthValidator,
        integerValidator,
        emailValidator,
        cardNumberRule,
        isValidDateRule,
        lengthBetweenValidator,
      },

      // icons
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiClose,
        mdiMenuDown,
        mdiCreditCardOutline,
      },
    }
  },
}
</script>
<style lang="scss">
  .height-mid {
    border-radius: 0;
    margin: auto auto 0;
    height: auto;
    width: 28rem !important;
    position: fixed;
    overflow-y: auto;
    bottom: 0;
    // left: 50%;
    // transform: translateX(-50%);
  }

  @media only screen and (max-width: 600px) {
    .height-mid {
      width: 100% !important;
    }
  }

  .b-top {
    border-radius: 10px 10px 0 0 !important;
  }

  .close-btn {
    background-color: #353535;
  }

  .v-text-field--enclosed .v-input__append-inner {
    margin-block: auto;
  }

  .terms-and-policies {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
