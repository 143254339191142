// import axios from 'axios'
import { computed } from '@vue/composition-api'
import { asyncLoading } from 'vuejs-loading-plugin'
import { logUserActivity } from '@api'
import { isIOS } from '@core/utils'

export default function useShare() {
  const isSupportedShare = computed(() => {
    if (navigator.canShare && navigator.share) return true

    return false
  })
  const canShare = data => navigator.canShare(data)

  const shareURL = (title, text, url) => {
    try {
      const shareData = {
        title,
        text,
      }
      if (canShare(shareData)) navigator.share(shareData)
    } catch (err) {
      console.error(`Error: ${err}${url}`)
    }
  }

  const shareFile = async (shareTitle, shareText, shareToFile, shareName = 'Fanaty', log = null) => {
    try {
      const servicePromise = fetch(shareToFile)

      asyncLoading(servicePromise).then().catch()
      const [materializedPromise] = await Promise.all([servicePromise])

      const blob = await materializedPromise.blob()
      const file = new File([blob], `${shareName}.mp4`, { type: 'video/mp4' })

      let shareData = {}
      if (isIOS()) {
        shareData = {
          files: [file],
        }
      } else {
        shareData = {
          title: shareTitle,
          text: shareText,
          files: [file],
        }
      }
      await navigator.share(shareData)

      if (log) logUserActivity(log)
    } catch (err) {
      console.error('err', err)
    }
  }

  return {
    isSupportedShare,
    canShare,
    shareURL,
    shareFile,
  }
}
