<template>
  <v-dialog
    v-model="isOpen"
    width="350px"
    @click:outside="$emit('update:is-open', false)"
  >
    <v-card
      class="py-5"
      color="#222222"
    >
      <v-icon
        class="absolute-close"
        @click="$emit('update:is-open', false)"
      >
        {{ icons.mdiClose }}
      </v-icon>

      <v-card-text class="pt-8 pb-0">
        <v-card-title class="justify-center">
          {{ t('reservations.are_you_sure') }}
        </v-card-title>
        <v-card-subtitle class="justify-center text-center pb-0 mt-1">
          {{ t('reservations.this_will_cancel') }}
        </v-card-subtitle>
      </v-card-text>

      <v-card-text>
        <v-row class="my-5 px-0 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center flex-column"
          >
            <v-btn
              class="text-capitalize"
              color="secondary"
              dark
              rounded
              block
              @click="onSave"
            >
              {{ t('reservations.yes_cancel_reservation') }}
            </v-btn>

            <v-btn
              class="no-bg-hover text-capitalize mt-2"
              color="secondary"
              dark
              rounded
              block
              text
              @click="computedIsOpen = false"
            >
              {{ t('reservations.no_kee_reservation') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
import { computed } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import useCryptoJs from '@core/utils/useCryptoJs'

export default {
  name: 'CancelReservation',
  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { userData } = useCryptoJs()

    const computedIsOpen = computed({
      set: val => emit('update:is-open', val),
      get: () => props.isOpen,
    })

    const onSave = () => {
      emit('onSave')
    }

    return {
      // data
      userData,

      // computed
      computedIsOpen,

      // methods
      onSave,

      // i18n
      t,

      // icons
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
  .absolute-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #222222;
    color: #ffffff6b;
    border-radius: 50px !important;
    padding: 2px;
  }

  .phone-item {
    min-height: 25px;
    height: 25px;
  }

  .btn-not-hov {
    color: #ff3232 !important;
    caret-color: #ff3232 !important;
    cursor: pointer;
  }

  .no-bg-hover::before {
    background-color: transparent !important;
  }
</style>
