<template>
  <div>
    <v-row class="px-3 py-1">
      <v-col
        cols="12"
        class="pa-1 d-flex justify-space-between align-center"
      >
        <span class="d-flex align-center lighterGray--text">
          {{ title }}
          <v-icon
            v-if="titleIcon"
            class="ml-2"
          >
            {{ titleIcon }}
          </v-icon>
        </span>

        <v-btn
          v-if="showAction"
          class="pa-0 text-center no-bg-hover mr-1"
          style="
            text-transform: inherit;
            color: #FF3232;
            min-width: 60px;
            "
          text
          :ripple="false"
          @click="() => $emit('action')"
        >
          {{ actionText }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      class="pa-1 mt-0"
    >
      <v-col
        cols="12"
        class="py-2 px-3 d-flex justify-space-between align-center"
      >
        <v-card
          width="100%"
          color="#333333"
          rounded="lg"
          :style="cardBorder !== 'primary' ? `border-color: ${resolveStatusColor(cardBorder)}` : ''"
        >
          <v-card-title
            :class="`${showCardText ? 'pb-2' : 'pb-5'} text-sm d-flex justify-space-between`"
          >
            <span>{{ cardTitle }}</span>
            <span :class="cardBorder !== 'primary' ? `${cardBorder}--text` : ''">{{ cardStatus }}</span>
          </v-card-title>
          <v-card-text
            v-if="showCardText"
            class="text-sm lighterGray--text"
          >
            {{ cardText }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { mdiHelpCircleOutline } from '@mdi/js'
import { getVuetify } from '@/@core/utils'

export default {
  name: 'VideoCard',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    titleIcon: {
      type: String,
      default: '',
    },
    showAction: {
      type: Boolean,
      default: false,
    },
    actionText: {
      type: String,
      default: '',
    },
    cardTitle: {
      type: String,
      default: '',
    },
    cardStatus: {
      type: String,
      default: '',
    },
    cardText: {
      type: String,
      default: '',
    },
    cardBorder: {
      type: String,
      default: '',
    },
    showCardText: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const $vuetify = getVuetify()
    const computedTitle = computed(() => props.title)

    const resolveStatusColor = status => $vuetify.theme.currentTheme[status]

    return {
      // computed
      computedTitle,

      resolveStatusColor,
      avatarText,

      icons: {
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>
