<template>
  <div>
    <v-row class="px-3 py-1">
      <v-col
        cols="12"
        class="pa-1 d-flex justify-space-between align-center"
      >
        <span>{{ title }}</span>

        <v-btn
          class="pa-0 mr-1 text-center no-bg-hover cursor-default"
          style="
            text-transform: inherit;
            min-width: 60px;
            height: 25px !important;
          "
          text
          color="secondary"
          :ripple="false"
          @click="$emit('createHighlights')"
        >
          {{ t('reservations.create_highlights') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      v-for="(highlight, index) in highlights"
      :key="index"
      class="pa-1"
    >
      <v-col class="px-3">
        <v-card
          color="#333333"
          class="rounded-lg"
          elevation="15"
        >
          <div>
            <video-js
              :id="`highlightVideo${index}`"
              :ref="`highlightVideo${index}`"
              class="video-player-box"
              preload="auto"
              :name="`highlight-${highlight.name}-${index}`"
            />
            <v-chip
              class="absolute-chip text-xs"
              color="#1d1d1dd4"
              small
            >
              {{ highlight.time_str }}
            </v-chip>
          </div>
          <v-card-actions class="d-flex justify-space-between align-start py-3">
            <div class="d-flex align-center">
              <v-avatar
                color="primary"
                :class="highlight.user_avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="40"
              >
                <v-img
                  v-if="highlight.user_avatar"
                  :src="highlight.user_avatar"
                ></v-img>
                <span
                  v-else
                  class="font-weight-medium"
                >{{ avatarText(highlight.fullname) }}</span>
              </v-avatar>
              <div class="d-flex flex-column ml-2 justify-center">
                <div class="d-flex flex-column ">
                  <span class="white--text">
                    {{ highlight.name }}
                  </span>
                  <span class="text-sm">{{ highlight.user_name }}</span>
                </div>
              </div>
            </div>

            <div>
              <span class="text-sm">
                {{ highlight.type_str }} {{ t('tooltip.at_minute') }} {{ highlight.start_time_str }}
              </span>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script src="https://unpkg.com/video.js/dist/video.js"></script>
<script src="https://unpkg.com/@videojs/http-streaming/dist/videojs-http-streaming.js"></script>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { mdiShare} from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import { useUtils } from '@core/libs/i18n'

import useCryptoJs from '@core/utils/useCryptoJs'
import useShare from '@core/utils/useShare'
import videojs from 'video.js'

export default {
  name: 'HighlightList',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    highlights: {
      type: Array,
      default: () => [],
      required: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    isPrivate: {
      type: Boolean,
      default: false,
      required: true,
    },
    ipAddress: {
      type: Object,
      default: () => {},
      required: true,
    },
    matchCode: {
      type: String,
      default: '',
      required: true,
    },
  },
  setup(props) {
    const { shareFile } = useShare()
    const { userData } = useCryptoJs()
    const { t } = useUtils()

    const USER_ACT_SHARE_HIGHLIGHT_LANDING_APP = 'Share Highlight Landing App'

    const playerOptions = ref({
      controls: true,
      responsive: true,
      breakpoints: true,
      fill: true,
      autoplay: false,
      muted: true,
      loop: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [],
      poster: 'https://fanaty-general-ohio.s3.us-east-2.amazonaws.com/no-thumb.jpg',
      notSupportedMessage: 'Este video no está disponible temporalmente, intente nuevamente más tarde.',
      usingPlugin: true,
      fluid: true,
      aspectRatio: '16:9',
    })

    const computedTitle = computed(() => props.title)
    const computedHighlights = computed(() => props.highlights)
    const computedIsPrivate = computed(() => props.isPrivate)
    const computedIpAddress  = computed(() => props.ipAddress)
    const computedMatchCode  = computed(() => props.matchCode)

    const getLog = (reference) => {
      const log = {
        ip_address: computedIsPrivate.value ? null : computedIpAddress.value.ip,
        user_id: computedIsPrivate.value ? userData.value.user_id : null,
        match_code: computedMatchCode.value,
        reference: reference,
        action: USER_ACT_SHARE_HIGHLIGHT_LANDING_APP,
      }

      return log
    }

    onMounted(async () => {
      computedHighlights.value.forEach((e, i) => {
        let playerOpt = playerOptions.value
        playerOpt.sources = []
        playerOpt.poster = e.thumbnail
        playerOpt.sources.push({
          type: 'video/mp4',
          src: e.highlight_url,
        })

        const getPlayer = videojs.getPlayer(`highlightVideo${i}`)
        if (getPlayer) getPlayer.dispose()
        const player = videojs(`highlightVideo${i}`, playerOpt)
      })
    })

    return {
      // data
      USER_ACT_SHARE_HIGHLIGHT_LANDING_APP,
      playerOptions,
      userData,

      // computed
      computedTitle,
      computedHighlights,
      computedIsPrivate,
      computedIpAddress,
      computedMatchCode,

      //methods
      avatarText,
      shareFile,
      getLog,

      // i18n
      t,

      // icons
      icons: {
        mdiShare,
        sendIcon: require('@/assets/images/svg/send.svg')
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.absolute-chip {
  bottom: 110px;
  right: 15px;
  position: absolute;
  z-index: 1;
}

.video-js .vjs-tech {
  border-radius: 8px 8px 0px 0px;
}

.video-js .vjs-big-play-button {
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('https://pngimage.net/wp-content/uploads/2018/06/svt-png-9.png');
  background-repeat: no-repeat;
  background-size: 46px;
  background-position: 50% calc(50% - 10px);
  border: none !important;
  box-shadow: none !important;
  &:before {
    content: "";
    display: none;
  }
  &:hover {
    background-color: transparent;
    opacity: .7;
  }
}
</style>
