<template>
  <div>
    <v-row
      class="pa-1"
    >
      <v-col
        cols="12"
        class="py-2 px-3"
      >
        <v-card
          color="#333333"
          width="100%"
          rounded="lg"
        >
          <v-card-title class="d-flex justify-space-between align-center text-body-1">
            <span>{{ title }}</span>
            <span>{{ subtitle && subtitle > 0 ? `${formatCurrency(subtitle)} USD` : t('status.free') }}</span>
          </v-card-title>

          <v-card-text class="text-body-1 lighterGray--text">
            <div class="mb-1">
              <v-icon
                class="mr-1"
                size="25"
              >
                {{ icons.mdiCalendarToday }}
              </v-icon>
              {{ date }}
            </div>

            <div class="mb-1">
              <v-icon
                class="mr-1"
                size="25"
              >
                {{ icons.mdiClockOutline }}
              </v-icon>
              {{ startTime }}
            </div>

            <div>
              <v-icon
                class="mr-1"
                size="25"
              >
                {{ icons.mdiMapMarkerOutline }}
              </v-icon>
              {{ location }}
            </div>
          </v-card-text>
        </v-card>

        <v-alert
          v-if="subtitle > 0 || isCancel"
          class="mt-4 rounded-lg"
          :class="{'mb-0': !(canCancel || (!canCancel && !isCancel && paymentStatus !== 'paid' && subtitle > 0))}"
          text
          :type="resolveStatusType(paymentStatus)"
          :color="resolveStatusColor(paymentStatus)"
        >
          <span class="white--text text-sm">{{ resolveStatusText(paymentStatus) }}</span>
          <template slot="prepend">
            <v-icon
              class="mr-2"
              size="25"
              :color="paymentStatus"
            >
              {{ resolveStatusIcon(paymentStatus) }}
            </v-icon>
          </template>
        </v-alert>

        <span
          v-if="canCancel"
          class="mt-4"
        >{{ `${t('reservations.free_cancellation')} ${cancelDate}` }}</span>
        <span
          v-else-if="!canCancel && !isCancel && paymentStatus !== 'paid' && subtitle > 0"
          class="mt-4"
        >{{ `${t('reservations.non_refundable')}` }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiCalendarToday,
  mdiClockOutline,
  mdiMapMarkerOutline,
  mdiClose,
  mdiCheck,
  mdiAlertCircleOutline,
  mdiCheckCircle,
  mdiAlertCircle,
} from '@mdi/js'
import { formatCurrency } from '@core/utils'
import { useUtils } from '@core/libs/i18n'

export default {
  name: 'InfoCard',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    subtitle: {
      type: Number,
      default: 0,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
    startTime: {
      type: String,
      default: '',
    },
    location: {
      type: String,
      default: '',
    },
    cancelDate: {
      type: String,
      default: '',
    },
    isCancel: {
      type: Boolean,
      default: false,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
    isConfirm: {
      type: Boolean,
      default: false,
    },
    confirmDate: {
      type: String,
      default: '',
    },
    confirmCode: {
      type: String,
      default: '',
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
    isNow: {
      type: Boolean,
      default: false,
    },
    paymentStatus: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { t } = useUtils()

    const resolveStatusType = status => {
      if (status === 'cancelled') return 'error'
      if (status === 'paid') return 'success'
      if (['pending', 'declined'].includes(status)) return 'warning'
      if (status === 'hold') return 'info'

      return 'warning'
    }

    const resolveStatusColor = status => {
      if (status === 'cancelled') return 'cancelled'
      if (status === 'paid') return 'paid'
      if (['pending', 'declined'].includes(status)) return 'pending'
      if (status === 'hold') return 'hold'

      return ''
    }

    const resolveStatusIcon = status => {
      if (status === 'cancelled') return mdiClose
      if (status === 'paid') return mdiCheck
      if (['pending', 'declined', 'hold'].includes(status)) return mdiAlertCircleOutline

      return ''
    }

    const resolveStatusText = status => {
      if (status === 'cancelled') return t('reservations.reservation_has_been_cancelled')
      if (status === 'paid') return t('reservations.reservation_was_paid')
      if (status === 'pending') return t('reservations.reservation_is_pending')
      if (status === 'declined') return t('reservations.reservation_was_declined')
      if (status === 'hold') {
        if (props.isNow) return `${t('pay_dialog.reservation_will_be_charged_now')}  ${t('pay_dialog.card_ending_in')} ${props.confirmCode}.`

        return `${t('pay_dialog.reservation_will_be_charged')} ${props.confirmDate} ${t('pay_dialog.card_ending_in')} ${props.confirmCode}.`
      }

      return ''
    }

    return {
      resolveStatusType,
      resolveStatusColor,
      resolveStatusIcon,
      resolveStatusText,
      formatCurrency,
      t,

      icons: {
        mdiCalendarToday,
        mdiClockOutline,
        mdiMapMarkerOutline,
        mdiClose,
        mdiCheck,
        mdiAlertCircleOutline,
        mdiCheckCircle,
        mdiAlertCircle,
      },
    }
  },
}
</script>
